import {registerStyle, styleRule, StyleSheet, UI} from "stem-core/src/ui/UI.js";
import {NOOP_FUNCTION} from "../../Utils.js";
import {isSmallScreen} from "../../Utils.js";
import {BLINK_PAY_URL, CARD_ERROR_MAX_LENGTH, STRIPE_SRC} from "../../Constants.js";
import {ensure} from "../../../stem-core/src/base/Require.js";
import {Messages} from "../../Messages.js";
import {apiAddPaymentMethodToken} from "../../../client/state/PaymentMethodStore.js";
import {PaymentProcessorStore, PaymentProcessorType} from "../../../client/state/PaymentProcessorStore.js";
import {wrapInSpinner} from "../../../core/ui/LoadingSpinner.jsx";
import {Flow} from "../../panel/flows/Flow.js";
import {iFrameMerchantService} from "../../services/IframeMerchantService.js";


const DEFAULT_STRIPE_STYLE = (themeProps) => ({
    invalid: {
        color: themeProps.ERROR_COLOR,
        iconColor: themeProps.ERROR_COLOR,
    },
    base: {
        color: themeProps.INPUT_TEXT_COLOR,
        iconColor: themeProps.INPUT_TEXT_COLOR,
        fontSize: themeProps.FONT_SIZE_LARGE,
        fontFamily: themeProps.FONT_FAMILY_DEFAULT,
        fontWeight: themeProps.FONT_WEIGHT_REGULAR,
        "::placeholder": {
            color: themeProps.INPUT_PLACEHOLDER_COLOR,
            fontWeight: themeProps.FONT_WEIGHT_REGULAR,
        },
    },
});

export class StripeCardInputStyle extends StyleSheet {
    @styleRule
    container = {
        fontSize: () => (isSmallScreen() ? 14 : 16),
        color: this.themeProps.INPUT_TEXT_COLOR,
        position: "relative",
        overflow: "initial",
        cursor: "text",
    };

    @styleRule
    inputContainer = {
        width: "100%",
        position: "relative",
        fontSize: "inherit",
        height: 46,
        background: this.themeProps.INPUT_BACKGROUND,
        borderRadius: this.themeProps.BASE_BORDER_RADIUS,
        border: () => "1px solid " + this.themeProps.INPUT_BORDER_COLOR,
        transition: "box-shadow 0.2s ease",
        ...this.themeProps.MERCHANT_INPUT_STYLE,
    };

    @styleRule
    inputContainerFocused = {
        border: () => "1px solid " + this.themeProps.INPUT_BORDER_ACTIVE_COLOR,
    };

    @styleRule
    message = {
        paddingBottom: 12,
        fontSize: 14,
    };

    // TODO @cleanup rename to messageSuccess and messageError
    @styleRule
    successMessage = {
        color: this.themeProps.SUCCESS_COLOR,
    };

    @styleRule
    errorMessage = {
        color: this.themeProps.ERROR_COLOR,
        marginTop: 10,
    };

    @styleRule
    error = {
        borderColor: this.themeProps.ERROR_COLOR,
        ":hover": {
            borderColor: this.themeProps.ERROR_COLOR + " !important",
        },
        " input": {
            color: this.themeProps.ERROR_COLOR + " !important",
        },
        " input::placeholder": {
            color: this.themeProps.ERROR_COLOR + " !important",
        }
    };
}

// TODO @cleanup a lot of this shitcode is still like this because it was split in two classes
@registerStyle(StripeCardInputStyle)
export class StripeCardInput extends UI.Element {
    focused = false;
    inputComplete = false;
    card = null;
    validInputs = false;
    paymentProcessorSrc = STRIPE_SRC;
    error = null;

    getDefaultPaymentProcessor() {
        let paymentProcessors = PaymentProcessorStore.filterBy({provider: PaymentProcessorType.STRIPE});
        const merchantOnlyPP = paymentProcessors.filter(pp => pp.merchantId == iFrameMerchantService.merchantId); // Even if merchantId is null, it's what we want
        if (merchantOnlyPP.length) {
            paymentProcessors = merchantOnlyPP;
        } else {
            paymentProcessors = paymentProcessors.filter(pp => pp.merchantId == null);
        }
        if (paymentProcessors.length !== 1) {
            throw "Invalid configuration: Define exactly one payment processor";
        }
        return paymentProcessors[0];
    }

    getDefaultOptions(options) {
        return {
            onChange: NOOP_FUNCTION,
            label: "addCard",
            autofocus: true,
            fonts: [{
                family: "Lato",
                src: `url(${BLINK_PAY_URL}/resources/fonts/LatoLatin-Regular.woff)`,
                weight: "400",
            }],
            paymentProcessor: this.getDefaultPaymentProcessor(),
        };
    }

    getChildrenToRender() {
        const {styleSheet} = this;
        const {success} = this.options;
        const inputContainerClasses = styleSheet.inputContainer +
            (success ? styleSheet.success : this.error ? styleSheet.error : "") +
            (this.focused ? styleSheet.inputContainerFocused : "");

        let {error} = this;
        if (error?.length > CARD_ERROR_MAX_LENGTH) {
            error = Messages.genericCardError;
        }

        return [
            <div className={inputContainerClasses}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: ".25rem 5px",
                        alignItems: "center",
                        paddingLeft: 8,
                        height: "100%",
                    }}>
                    <div id="card-element" style={{flex: 1}}/>
                </div>
                <div className={styleSheet.message + styleSheet.successMessage}>{success}</div>
            </div>,
            error && <div testId="cardInputError" className={styleSheet.message + styleSheet.errorMessage}>{error}</div>,
        ];
    }

    hasValidSecureFields() {
        return this.validInputs;
    }

    isLoaded() {
        return !!this.card;
    }

    isInputComplete() {
        return this.inputComplete;
    }

    async prepareRequest(scope = Flow.paymentMethod.scope, data = {}) {
        const {paymentProcessor} = this.options;
        Flow.paymentMethod.unprocessedNewToken = null;
        this.updateOptions({error: null});
        const response = await this.stripe.createToken(this.card, data);

        if (response.error) {
            this.updateOptions({error: response.error.message});
            throw this.error;
        }

        this.card.blur();
        Flow.paymentMethod.unprocessedNewToken = {
            token: response.token,
            scope,
            paymentProcessor,
        }
    }

    @wrapInSpinner
    async confirmCard(scope = Flow.paymentMethod.scope, data = {}) {
        await this.prepareRequest(scope, data);

        try {
            return await Flow.paymentMethod.sendUnprocessedNewToken();
        } catch (error) {
            this.updateOptions({error: error.message});
            throw error;
        }
    }

    getCardElement() {
        const {themeProps} = this;
        const {paymentProcessor} = this.options;

        const stripeElementsOptions = {
            fonts: this.options.fonts,
        }

        const stripeCardOptions = {
            style: DEFAULT_STRIPE_STYLE(themeProps),
        };

        this.stripe = Stripe(paymentProcessor.publicKey);
        this.card = this.stripe.elements(stripeElementsOptions).create("card", stripeCardOptions);

        this.card.addEventListener("change", event => {
            if (event.error) {
                this.validInputs = false;
                this.error = event.error.message;
                this.redraw();
            } else {
                this.validInputs = true;
                this.error = null;
                this.redraw();
            }
            this.inputComplete = event.complete;
            this.options.onChange();
        });

        if (this.options.autofocus) {
            this.card.addEventListener("ready", () => {
                this.card.focus();
            });
        }

        return this.card;
    }

    async insertPaymentProcessorElement() {
        await ensure(this.paymentProcessorSrc);
        const insertCardInput = this.attachInterval(() => {
            // TODO I'm not sure what this does, seems crap
            if (!document.getElementById("card-element")) {
                return;
            }
            clearInterval(insertCardInput);
            this.card = this.getCardElement();
            this.card.on("focus", () => {
                this.focused = true;
                this.error = null;
                this.redraw();
            });
            this.card.on("blur", () => {
                this.focused = false;
                this.redraw();
            });
            this.card.mount("#card-element");
            this.dispatch("load");
        }, 200);
    }

    onMount() {
        this.addClickListener(() => {
            if (this.card) {
                this.card.focus();
            }
        });
        this.insertPaymentProcessorElement();
    }
}
