import {UI} from "../../../../stem-core/src/ui/UIBase.js";
import {BaseEnum, makeEnum} from "../../../../stem-core/src/state/BaseEnum.js";
import {Dispatcher} from "../../../../stem-core/src/base/Dispatcher.js";
import {Router} from "../../../../stem-core/src/ui/Router.jsx";
import {wrapInSpinner} from "../../../../core/ui/LoadingSpinner.jsx";
import {BackButton} from "../../../../core/ui/LinkButton.jsx";
import {Messages} from "../../../Messages.js";
import {NOOP_FUNCTION} from "../../../../stem-core/src/base/Utils.js";
import {Flow} from "../Flow.js";

@makeEnum
export class FlowInputIntent extends BaseEnum {
    static CHOOSE_VALUE;            // The input is probably a list of all value
    static ADD_VALUE;               // The input for adding a new value
    static EDIT_VALUE;              // Means we want to modify a store object directly (unadvisible usually)
}

export class BaseFlowStep extends Dispatcher {
    shouldPrompt = () => true; // Can be a bool or function, in case we want to skip this step.
    selectedValue = null;
    inlineSubflow = false; // If we're supposed to get back where we were, implies noHistory
    noHistory = false; // If true is means that a Back won't include us (on a change started from a review)
    inputIntent = FlowInputIntent.CHOOSE_VALUE;
    continueLabel = Messages.continue; // For the flow in general, might get overwritten based on inputIntent
    onSelectSuccess = NOOP_FUNCTION; // This should rarely be passed in, and never if we have a prompt call
    
    constructor(options = {}) {
        super(options);

        this.update(options);
    }

    update(options, sendDispatch = true) {
        Object.assign(this, options);
        if (sendDispatch) {
            this.dispatch();
        }
        return this;
    }

    // Some fields should be initialized only when needed, since
    getDefaultFields() {
        return {};
    }

    getFieldsToReset() {
        const obj = new this.constructor();
        obj.getDefaultFields();
        if (this.selectedValue) {
            delete obj.selectedValue;
        }
        for (const key of Object.keys(obj)) {
            if (this.initOptions?.hasOwnProperty(key)) {
                delete obj[key];
            }
        }
        return obj;
    }

    init(options) {
        this.initOptions = options || {};
        // First reset all fields
        this.update(this.getFieldsToReset(), false);

        this.update(options, false);

        this.flowHandler = Flow.activeFlowHandler; // Just to keep track that we've been initialized already for this flow

        return this; // For easier syntax when specifying an array of steps
    }

    async updateAndResolve(options = {}) {
        this.update(options);
        await this.onSelectSuccess();
    }

    async prompt(options) {
        // Since some steps might have changed out state (auth for instance),
        // default fields logic needs to be executed now.
        if (this.flowHandlerWithInit !== Flow.activeFlowHandler) {
            this.flowHandlerWithInit = Flow.activeFlowHandler;
            // Same reset as in init
            this.update(this.getFieldsToReset(), false);
        }

        if (options) {
            this.lastPromptOptions = options; // TODO @flow should be merged with initOptions?
            // TODO @flow2 this might have to be an init?
            this.update(options);
        }

        return new Promise((resolve) => {
            this.onSelectSuccess = () => {
                if (this.noHistory || this.inlineSubflow) {
                    Router.localHistory.pop();
                }
                resolve(this.selectedValue);
                if (this.inlineSubflow) {
                    Router.onPopState();
                }
            }
            this.beforeShowPanel();
            this.showPanel();
        });
    }

    // When clicking on "Add new address" under all the radio inputs
    enterNewValue() {
        this.inputIntent = FlowInputIntent.ADD_VALUE;
        this.showPanel();
    }

    // Means we're adding a new value, while the intent was to select a value
    insideNewValue() {
        const originalInputIntent = this.lastPromptOptions?.inputIntent || FlowInputIntent.CHOOSE_VALUE;
        return this.inputIntent === FlowInputIntent.ADD_VALUE && this.inputIntent !== originalInputIntent;
    }

    exitNewValue() {
        this.inputIntent = FlowInputIntent.CHOOSE_VALUE; // TODO @flow2 this should have been a sub-step
        this.showPanel();
    }

    @wrapInSpinner
    async selectValue(value) {
        if (!value) {
            // A validation error must have occurred
            return;
        }

        this.selectedValue = value;

        if (this.insideNewValue()) {
            // For example, if we clicked "Add Address", get back to selecting an address
            this.exitNewValue();
        } else {
            await this.onSelectSuccess();
        }
    }

    canGoBack() {
        if (this.insideNewValue()) {
            return true;
        }
        // TODO @flow2 stop it with the empty crap
        // TODO @flow2 this is sometimes wrong, seems like the Router puts the same path twice in history.
        const previousPath = Router.getHistoricPath(-1);
        return previousPath && previousPath !== "/empty" && previousPath !== "empty";
    }

    goBack() {
        if (this.insideNewValue()) {
            this.exitNewValue();
        } else {
            // TODO @flow2 close the loop here and exit the subflow if back goes to empty
            Flow.back();
        }
    }

    renderBackButton() {
        if (this.canGoBack()) {
            return <BackButton onClick={() => this.goBack()} />;
        }
        return null;
    }

    getContinueButtonLabel(overridesPerInputIntent = {}) {
        return overridesPerInputIntent[this.inputIntent] || this.continueLabel || Messages.continue;
    }

    haveOptionsAvailable() {
        // We assume that if there was an option, we would have initialized it already
        return this.selectedValue;
    }

    getInputComponentClass(intentIntentClasses) {
        let {inputIntent} = this;

        const cantChoose = inputIntent === FlowInputIntent.CHOOSE_VALUE && !this.haveOptionsAvailable();

        // We may have to add a new value, even though our intent is different
        if (cantChoose || inputIntent === FlowInputIntent.EDIT_VALUE) {
            inputIntent = FlowInputIntent.ADD_VALUE;
        }

        return intentIntentClasses[inputIntent];
    }

    // You can add script prefetching or whatever you want in here
    beforeShowPanel() {}

    showPanel() {
        throw Error("Not Implemented");
    }
}
